import en from "vee-validate/dist/locale/en.json";
export default {
    code: "en",
    // messages: en.messages,
    messages: {
        alpha: "{_field_} may only contain alphabetic characters",
        alpha_dash: "{_field_} may contain alpha-numeric characters as well as dashes and underscores",
        alpha_num: "{_field_} may only contain alpha-numeric characters",
        alpha_spaces: "{_field_} may only contain alphabetic characters as well as spaces",
        between: "{_field_} must be between {min} and {max}",
        confirmed: "{_field_} confirmation does not match",
        digits: "{_field_} must be numeric and exactly contain {length} digits",
        dimensions: "{_field_} must be {width} pixels by {height} pixels",
        double: "{_field_} must be a valid decimal",
        email: "{_field_} must be a valid email",
        excluded: "{_field_} is not a valid value",
        ext: "{_field_} is not a valid file",
        image: "{_field_} must be an image",
        integer: "{_field_} must be an integer",
        length: "{_field_} must be {length} long",
        max: "{_field_} may not be greater than {length} characters",
        max_value: "{_field_} must be {max} or less",
        mimes: "{_field_} must have a valid file type",
        min: "{_field_} must be at least {length} characters",
        min_value: "{_field_} must be {min} or more",
        numeric: "{_field_} may only contain numeric characters",
        oneOf: "{_field_} is not a valid value",
        regex: "{_field_} format is invalid",
        required: "{_field_} is required",
        required_if: "{_field_} is required",
        size: "{_field_} size must be less than {size}KB",
    },
    names: {
        email: "E-mail",
        password: "Password",
    },
    fields: {
        password: {
            min: "{_field_} is too short, you want to get hacked?"
        }
    }
};

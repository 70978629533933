import Vue from 'vue'
import FeatherIcon from '@core/components/feather-icon/FeatherIcon.vue'
// import customDropDown from '@/components/dropdown/customDropdown.vue'
import Help from '@/components/HelpDesk/Help.vue'
import TimeSheetLoader from '@/components/loaders/timesheet-loader.vue'
import VueDatePicker from './views/TaskSummary/calender.vue'
import OptionDropdown from './components/optiondropdown/OptionDropdown.vue'
import Pagination from './components/pagination/index.vue'
import TableLoader from './components/loaders/table-loading.vue'
import UserCard from './components/userInfo/index.vue'

Vue.component(FeatherIcon.name, FeatherIcon)
Vue.component('custom-calender', VueDatePicker)
// Vue.component('custom-drop-down', customDropDown)
Vue.component('Help', Help)
Vue.component('time-sheet-loader', TimeSheetLoader)
Vue.component('Pagination', Pagination)
Vue.component('option-drop-down', OptionDropdown)
Vue.component('table-loader', TableLoader)
Vue.component('user-card', UserCard)

import Vue from 'vue'

// axios
const baseURL = process.env.VUE_APP_API_URL+'/'+process.env.VUE_APP_API_VERSION
import axios from 'axios'

const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  // baseURL: 'https://some-domain.com/api/',
  // timeout: 1000,
  // headers: {'X-Custom-Header': 'foobar'}
  baseURL: baseURL,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  }
})

Vue.prototype.$http = axiosIns

export default axiosIns


import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  ignoreDuplicateNavigation: true,
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    /* Login's Route Start */
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/Login.vue'),
      meta: {
        layout: 'full',
        authorize: [],
      },
    },
    /* Login's Route End */

    /* DashBoard's Route Start */
    {
      path: '/',
      name: 'dashboard',
      component: () => import('@/views/NewDashBoard.vue'),
      meta: { authorize: [] },
    },
    {
      path: '/hr-dashboard',
      name: 'hrDashBoard',
      component: () => import('@/views/HrDashboard.vue'),
      meta: { authorize: ['SA'] },
    },
    {
      path: '/q-assure-dashboard',
      name: 'qassureDashBoard',
      component: () => import('@/views/Dashboard/Qassure/index.vue'),
      meta: { authorize: ['SA', 'EMP'] },
    },

    /* DashBoard's Route end */

    /* ClockInOut's & ClockTimeSheet Route Start */
    {
      path: '/clock-in-out',
      name: 'clockInOut',
      component: () => import('@/views/clockInOut/index.vue'),
      meta: { authorize: [] },
    },
    {
      path: '/clock-time-sheet',
      name: 'hrTimeSheet',
      component: () => import('@/views/hrTimeSheet/ViewEditHeader.vue'),
    },
    {
      path: '/user-log',
      name: 'userLog',
      component: () => import('@/views/clockInOut/userDailyLogView.vue'),
      meta: { authorize: ['HR', 'SA'] },
    },
    /* ClockInOut's & ClockTimeSheet Route End */

    /* Task TimeSheet Manage's Route Start  */
    {
      path: '/task-time-log',
      name: 'timeSheetView',
      component: () => import('@/views/TaskTimeSheetManage.vue'),
      meta: { authorize: [] },
    },
    {
      path: '/task-time-log/:projectId',
      name: 'timeSheetViewDetail',
      component: () => import('@/views/TaskTimeSheetManage.vue'),
      meta: { authorize: [] },
    },
    {
      path: '/approval',
      name: 'approval',
      component: () => import('../views/approval/Approvals.vue'),
      meta: { authorize: [] },
    },
    {
      path: '/approval/view/:id',
      name: 'employeeApproval',
      component: () => import('../views/approval/NewEmpApproval.vue'),
      meta: { authorize: [] },
    },
    {
      path: '/approval-status',
      name: 'approvalStatus',
      component: () => import('../views/approval/ApprovalsStatus.vue'),
      meta: { authorize: [] },
    },
    /* Task TimeSheet Manage's Route End  */

    /* Report's Route Start */
    {
      path: '/summary-report',
      name: 'summary-report',
      component: () => import('@/views/TaskSummary/TaskSummaryReport.vue'),
      meta: { authorize: ['SA'] },
    },
    {
      path: '/sync-report',
      name: 'syncReports',
      component: () => import('@/components/reports/SyncReport.vue'),
    },
    {
      path: '/reports',
      name: 'reports',
      component: () => import('@/views/ReportMaster.vue'),
    },
    {
      path: '/skill-report',
      name: 'skillReports',
      component: () => import('@/components/reports/SkillReport.vue'),
    },
    {
      path: '/project-list',
      name: 'projectReports',
      component: () => import('@/components/reports/projectHealthReport/projectListing.vue'),
    },
    {
      path: '/project-health-report',
      name: 'projectHealthReports',
      component: () => import('@/components/reports/projectHealthReport/index.vue'),
    },
    {
      path: '/task-time-sheet-report',
      name: 'taskTimeSheetReport',
      component: () => import('@/components/reports/TimesheetReport.vue'),
    },
    {
      path: '/scheduled-reports',
      name: 'scheduledReport',
      component: () => import('@/components/reports/ScheduledReport.vue'),
    },
    {
      path: '/resource-availability-report',
      name: 'ResourceAvailabilityReport',
      component: () => import('@/components/reports/ResourceAvailabilityReport.vue'),
    },
    /* Report's Route End */

    /* Resource Planning's Route Start */
    {
      path: '/resource-planning',
      name: 'resourcePlanning',
      component: () => import('@/views/ResourcePlanning/ResourcePlanningReport.vue'),
    },
    {
      path: '/resource-actual',
      name: 'resourceActualPlanning',
      component: () => import('@/views/ResourcePlanning/ResourceActualReport.vue'),
    },
    /* Resource Planning's Route End */

    /* My team's Route Start */
    {
      path: '/my-team',
      name: 'myTeam',
      component: () => import('@/views/myTeam/MyTeam.vue'),
    },
    {
      path: '/my-team-detail/:id',
      name: 'MyTeamDetails',
      component: () => import('@/views/myTeam/MyTeamDetails.vue'),
    },
    /* My team's Route End */

    /* Help's Route Start */
    {
      path: '/user-manual',
      name: 'userManual',
      component: () => import('../views/project/HelpDesk/UserManual.vue'),
    },
    {
      path: '/media',
      name: 'mediaUpload',
      component: () => import('../views/project/HelpDesk/UploadMedia.vue'),
    },
    {
      path: '/media/:id',
      name: 'mediaView',
      component: () => import('../views/project/HelpDesk/viewMedia.vue'),
    },
    /* Help's Route End */

    /* Feedback's Route Start */
    {
      path: '/feedback',
      name: 'feedBack',
      component: () => import('../views/feedback/FeedBack.vue'),
      meta: { authorize: [] },
    },
    /* Feedback's Route End */

    /* Master Setting's Route Start */
    {
      path: '/master/clients',
      name: 'clients',
      component: () => import('@/views/masterSettings/ClientTable.vue'),
      meta: { authorize: ['SA'] },
    },
    {
      path: '/master/groups',
      name: 'groupTable',
      component: () => import('@/views/masterSettings/GroupTable.vue'),
      meta: { authorize: ['SA'] },
    },
    {
      path: '/master/project-scope',
      name: 'projectScope',
      component: () => import('@/views/masterSettings/ProjectScope.vue'),
      meta: { authorize: ['SA'] },
    },
    {
      path: '/master/primary-skill',
      name: 'primarySkill',
      component: () => import('@/views/masterSettings/PrimarySkill.vue'),
      meta: { authorize: ['HR', 'SA'] },
    },
    {
      path: '/master/tag',
      name: 'tag',
      component: () => import('@/views/masterSettings/Tag.vue'),
      meta: { authorize: ['SA'] },
    },
    {
      path: '/master/employee',
      name: 'user',
      component: () => import('@/views/masterSettings/UserTable.vue'),
      meta: { authorize: ['HR', 'SA'] },
    },
    /* Master Setting's Route End */

    /* Project's Route Start  */
    {
      path: '/projects',
      name: 'projects',
      component: () => import('@/views/project/AllProject.vue'),
      meta: { authorize: [] },
    },
    {
      path: '/non-projects',
      name: 'nonProjects',
      component: () => import('@/views/project/nonProjectList.vue'),
      meta: { authorize: ['SA'] },
    },

    {
      path: '/general-board/:id?',
      name: 'board',
      component: () => import('../views/Board/generalBoard.vue'),
    },
    {
      path: '/sprint/:id',
      name: 'sprint',
      component: () => import('../views/project/SprintPages/list.vue'),
      meta: { authorize: [] },
    },
    {
      path: '/notes',
      name: 'notes',
      component: () => import('../views/notes/Notes.vue'),
    },
    {
      path: '/archive/:type/:id',
      name: 'archive',
      props: true,
      component: () => import('../views/project/Board/Archive.vue'),
    },
    {
      path: '/:id/:id2/:id3?/:id4?/:id5?/:id6?/:id7?',
      name: 'projectTask',
      component: () => import('../views/project/AllProjectHeader.vue'),
      meta: { authorize: [] },
      beforeEnter: (to, from, next) => {
        if (
          to.params.id === 'summary'
          || to.params.id === 'team'
          || to.params.id === 'tasklist'
          || to.params.id === 'board'
          || to.params.id === 'master-plan'
          || to.params.id === 'qassure'
          || to.params.id === 'note'
        ) {
          next()
        }
      },
    },
    /* Project's Route End  */
    /* Other's Route Start */
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
    {
      path: '/not-authorized',
      name: 'notAuthorized',
      component: () => import('@/views/error/NotAuthorized.vue'),
      meta: {
        layout: 'full',
      },
    },
  ],
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

const checkToken = () => {
  if (store.state.app.authToken && store.state.app.userInfo) {
    return true
  }
  return false
}
router.beforeEach((to, from, next) => {
  if (to.name !== 'login') {
    if (checkToken()) {
      const currentUser = store.state.app.userInfo
      // check if route is restricted by role
      if (
        to.meta
        && to.meta.authorize
        && to.meta.authorize.length
        && !to.meta.authorize.includes(currentUser.role)
      ) {
        // role not authorised so redirect to home page
        next({
          name: 'notAuthorized',
        })
      } else {
        next()
      }
    } else {
      next({
        name: 'login',
      })
    }
  }
  next()
})

export default router

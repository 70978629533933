import Vue from 'vue'
import {
  BootstrapVue, ToastPlugin, ModalPlugin, IconsPlugin,
} from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'

import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'
import VueSkeletonLoader from 'skeleton-loader-vue'
import VueSweetalert2 from 'vue-sweetalert2'
import 'sweetalert2/dist/sweetalert2.min.css'
import Multiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.min.css'
import VueApexCharts from 'vue-apexcharts'

import CryptoJS from 'crypto-js'

import { MonthPicker, MonthPickerInput } from 'vue-month-picker'

import VueDatePicker from '@mathieustan/vue-datepicker'
import constantData from './constant/constantData.json'
import App from './App.vue'
import store from './store'
import '@mathieustan/vue-datepicker/dist/vue-datepicker.min.css'

// Global Components
import './global-components'

// axios
import axios from './libs/axios'

// 3rd party plugins
import '@/libs/portal-vue'
import '@/libs/toastification'

// Mixin
import './mixin.js'

// call router
import router from './router'

Vue.use(VueDatePicker)

Vue.use(CryptoJS)
Vue.use(VueApexCharts)
Vue.component('apexchart', VueApexCharts)

// register globally
Vue.component('multiselect', Multiselect)
Vue.use(VueSweetalert2)
Vue.component('vue-skeleton-loader', VueSkeletonLoader)
Vue.use(IconsPlugin)
Vue.component('v-select', vSelect)

Vue.use(MonthPicker)
Vue.use(MonthPickerInput)
Vue.prototype.$http = axios
Vue.prototype.$axios = axios

// BSV Plugin Registration
Vue.use(BootstrapVue)
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

// Composition API
Vue.use(VueCompositionAPI)

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

if (localStorage.getItem('authToken') && localStorage.getItem('user')) {
  // Decrypt token
  let token = CryptoJS.AES.decrypt(localStorage.getItem('authToken'), constantData.VUE_APP_SECRET_KEY)
  token = token.toString(CryptoJS.enc.Utf8)

  // Decrypt  user
  let user = CryptoJS.AES.decrypt(localStorage.getItem('user'), constantData.VUE_APP_SECRET_KEY)
  user = JSON.parse(user.toString(CryptoJS.enc.Utf8))

  store.commit('app/UPDATE_AUTH_TOKEN', token)
  store.commit('app/UPDATE_LOGIN_USER_INFO', user)

  // set redis key in store
  if (localStorage.getItem('redisKey')) {
    // Decrypt  redis key
    let redisKey = CryptoJS.AES.decrypt(localStorage.getItem('redisKey'), constantData.VUE_APP_SECRET_KEY)
    redisKey = redisKey.toString(CryptoJS.enc.Utf8)

    store.commit('app/UPDATE_REDIS_KEY', redisKey)
  }
}

export const eventBus = new Vue()

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')

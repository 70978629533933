import { $themeBreakpoints } from '@themeConfig'

export default {
  namespaced: true,
  state: {
    windowWidth: 0,
    shallShowOverlay: false,
    authToken: null,
    userInfo: null,
    userAccount: null,
    userTimer: [],
    manualButtonDisble: false,
    TimerTab: 0,
    redisKey: null,
    usersList: [],
    tagList: [],

    /** client list */
    clientList: [],

    /** project id */
    projectId: null,

    /** project board & listing task status list */
    projectTaskStatus: [],

    /** general board task status */
    generalBoardTaskStatus: [],

    /** general board task status */
    generalBoardColor: {},

    /** project Scope */
    projectScope: [],

    /** project detail */
    projectDetail: {},

    /** project board color */
    projectBoardColor: {},

    /** getProjectPlanLists */
    projectPlanLists: [],

    /** project type  */
    projectTypeList: [],

    /** TIMESHEET MODULE */

    /** dailyFilterDate */
    dailyFilterDate: null,

    /** dailyFilterProjectId */
    dailyFilterProjectId: null,

    /** projectFilterList */
    projectFilterList: {},

    /** weeklyStartDate */
    weeklyStartDate: null,

    /** weeklyEndDate */
    weeklyEndDate: null,

    /** reportingUserList */
    reportingUserList: [],

    /** manageByUsersList */
    manageByUsersList: [],

    /** groupList */
    groupList: [],

    /** userSkills */
    userSkills: [],

    /** Test Module */
    pageShow: 'test-suite',

    /** test plan */
    test_plan: {
      title: null,
      description: null,
      test_cases: [],
    },

    /** activeTab */
    activeTab: null,

    /** defact */
    defect: {
      id: null,
      defect_title: null,
      severity: null,
      issue_discription: null,
      step_to_reproduce: null,
      attachment: [],
      removedattachment: [],
      assignee: null,
      status: null,
      test_suite: null,
      defect_type: 'bug',
      comment: [],
    },

    testRun: {
      id: null,
      plan_id: null,
      env: null,
      run_title: null,
      description: null,
      plan_name: null,
    },

    /** test_run */

    test_run: {
      id: null,
      plan_id: null,
      start_time: null,
      end_time: null,
      action: null,
    },

    testSuite: {
      id: null,
      suite_name: null,
      parent_suite_id: null,
      edit_id: null,
      loader: false,
      search: null,
    },
    /** Project Suite list */
    projectSuiteList: [],

    /**
     * Suite list
     */
    suiteList: [],

    /**  suite list for dropdown */
    suiteListWithoutTestCase: [],

    /** suite id  */
    suiteId: null,

    /** test case id */
    testCaseId: [],

    /**
     * test plan data
     */
    testPlanFormData: {
      title: null,
      description: null,
      testPlanId: null,
      suiteId: [],
    },

    /** TestActiveName */
    testActiveName: null,

    /** Test Plan List */
    testPlan: [],

    /** projectUsers */
    projectUsers: [],

    /** Timesheet Rejected Dates */
    timeSheetRejectDates: {
      start_date: null,
      end_date: null,
    },

    /** test case list */
    testCaseList: [],

    /** back in testing */
    backAction: null,

    /** Store Open Accordion id */
    testCaseAccordionIds: [],

    /** testCaseIdOfRun */
    testCaseIdOfRun: null,

    /** defectFilter */
    defectFilter: {
      assignee: null,
      status: [],
      perPage: 25,
      search: null,
      suite_id: [],
      currentPage: 1,
      severity: [],
    },

    /** testRunFilter */
    testRunFilter: {
      search: null,
      perPage: null,
      currentPage: 1,
    },
    /** test_run_filter */
    test_run_filter: {
      search: null,
      status: [],
      perPage: 25,
      currentPage: 1,
    },

    /** testPlanFilter */
    testPlanFilter: {
      search: null,
      perPage: 25,
      currentPage: 1,
    },

    originalParentChildSuite: [],

    userManualList: [],
    originaluserManualList: [],

    /**  General & ptoject board backgroud color  */
    boardBackGroundColor: null,

    /** Layout type full screen / default */
    layoutType: 'default',

    /** productiveProjectList */
    productiveProjectList: [],
    dayOutWithSendMail: false,
    dayOutWithAdditionalDetail: null,
    dayInOutBtnAnimation: false,
    feedbackCount: {
      sendFeedbackCount: null,
      receviedFeedbackCount: null,
      allFeedbackCount: null,
    },
    clockInOutAnimation: false,

    /** Daily log model data to set tab chnage  */
    dailyLogModelData: {
      task_name: null,
      project_id: null,
      ticket_id: null,
      task_date: null,
      start_time: null,
      end_time: null,
      tags: [],
    },
    isProjectNotification: false,
    isFullScreenModal: false,

    /*  User Profile card */
    viewUserProfileId: null,
    isUserProfileOpen: false,

    /* department */
    departmentList: [],

    /* employeeType */
    employeeTypeList: [],

    /* divisionList */
    divisionList: [],
  },
  getters: {
    currentBreakPoint: state => {
      const { windowWidth } = state
      if (windowWidth >= $themeBreakpoints.xl) return 'xl'
      if (windowWidth >= $themeBreakpoints.lg) return 'lg'
      if (windowWidth >= $themeBreakpoints.md) return 'md'
      if (windowWidth >= $themeBreakpoints.sm) return 'sm'
      return 'xs'
    },
  },
  mutations: {
    UPDATE_WINDOW_WIDTH(state, val) {
      state.windowWidth = val
    },
    TOGGLE_OVERLAY(state, val) {
      state.shallShowOverlay = val !== undefined ? val : !state.shallShowOverlay
    },
    UPDATE_LOGIN_USER_INFO(state, val) {
      state.userInfo = val
    },

    UPDATE_AUTH_TOKEN(state, val) {
      state.authToken = val
    },
    UPDATE_MANANUAL_DISABLE_BUTTON_STATUS(state, val) {
      state.manualButtonDisble = val
    },
    // update redis key
    UPDATE_REDIS_KEY(state, val) {
      state.redisKey = val
    },

    /** update projectID */
    UPDATE_PROJECT_ID(state, val) {
      state.projectId = val
    },
    /** update userslist */
    UPDATE_USERS_LIST(state, val) {
      state.usersList = val
    },
    /** update tagList */
    UPDATE_TAGS_LIST(state, val) {
      state.tagList = val
    },

    /** update client list */
    UPDATE_CLIENT_LIST(state, val) {
      state.clientList = val
    },

    /** update projectTaskStatus */
    UPDATE_PROJECT_TASK_STATUS(state, val) {
      state.projectTaskStatus = val
    },

    /** update generalBoardTaskStatus */
    UPDATE_GENERAL_BOARD_TASK_STATUS(state, val) {
      state.generalBoardTaskStatus = val
    },

    /** update generalBoardColor */
    UPDATE_GENERAL_BOARD_COLOR(state, val) {
      state.generalBoardColor = val
    },

    /** update project scope */
    UPDATE_PROJECT_SCOPE(state, val) {
      state.projectScope = val
    },

    /** update project scope */
    UPDATE_PROJECT_DETAIL(state, val) {
      state.projectDetail = val
    },

    /** update projectBoardColor */
    UPDATE_PROJECT_BOARD_COLOR(state, val) {
      state.projectBoardColor = val
    },

    /** update project type list */
    UPDATE_PROJECT_TYPE_LIST(state, val) {
      state.projectTypeList = val
    },

    /** update projectPlanLists */
    UPDATE_PROJECT_PLAN_LIST(state, val) {
      state.projectPlanLists = val
    },

    /** update projectFilterList */
    UPDATE_PROJECT_FILTER_LIST(state, val) {
      state.projectFilterList = val
    },

    /** TimeSheet Module */

    /** update dailyFilterDate */
    UPDATE_DAILY_FILTER_DATE(state, val) {
      state.dailyFilterDate = val
    },
    /** update dailyFilterProjectId */
    UPDATE_DAILY_FILTER_PROJECTID(state, val) {
      state.dailyFilterProjectId = val
    },

    /** update  weekly start date */
    UPDATE_WEEKLY_START_DATE(state, val) {
      state.weeklyStartDate = val
    },

    /** update  weekly end date */
    UPDATE_WEEKLY_END_DATE(state, val) {
      state.weeklyEndDate = val
    },

    /** update  reportingUserList */
    UPDATE_MANAGEBY_USER_LIST(state, val) {
      state.manageByUsersList = val
    },

    /** update  reportingUserList */
    UPDATE_REPORTING_USER_LIST(state, val) {
      state.reportingUserList = val
    },

    /** update  groupList */
    UPDATE_GROUP_LIST(state, val) {
      state.groupList = val
    },
    /** update  groupList */
    UPDATE_USER_SKILLS(state, val) {
      state.userSkills = val
    },
    UPDATE_PAGESHOW(state, val) {
      state.pageShow = val
    },

    /** update projectSuiteList */
    UPDATE_PROJECT_SUITE_LIST(state, val) {
      state.projectSuiteList = val
    },

    /** update edit form data */
    UPDATE_SUITE_ID(state, val) {
      state.suiteId = val
    },

    /** update test Case Form Data */
    UPDATE_TEST_CASE_ID(state, val) {
      state.testCaseId = val
    },
    UPDATE_TEST_PLAN_FORM_DATA(state, val) {
      state.testPlanFormData = val
    },

    /** defect */
    UPDATE_DEFECT(state, val) {
      state.defect = val
    },

    /** testActiveName */
    UPDATE_TEST_ACTIVE_NAME(state, val) {
      state.testActiveName = val
    },

    /** test Plan */
    UPDATE_TEST_PLAN(state, val) {
      state.testPlan = val
    },

    /** test Plan */
    UPDATE_PROJECT_USERS(state, val) {
      state.projectUsers = val
    },

    UPDATE_SUITE_LIST(state, val) {
      state.suiteList = val
    },

    UPDATE_SUITE_LIST_WITHOUT_TESTCASE(state, val) {
      state.suiteListWithoutTestCase = val
    },

    UPDATE_TEST_SUITE(state, val) {
      state.testSuite = val
    },

    UPDATE_TEST_CASE_LIST(state, val) {
      state.testCaseList = val
    },

    /** back in testing */
    UPDATE_BACK_ACTION(state, val) {
      state.backAction = val
    },

    /** testCaseAccordionIds */
    UPDATE_TESTCASE_ACCORDION_IDS(state, val) {
      state.testCaseAccordionIds = val
    },

    /** UPDATE_TEST_CASE_ID_OF_RUN */
    UPDATE_TEST_CASE_ID_OF_RUN(state, val) {
      state.testCaseIdOfRun = val
    },

    /** UPDATE_DEFECT_FILTER */
    UPDATE_DEFECT_FILTER(state, val) {
      state.defectFilter = val
    },

    /** UPDATE_ACTIVE_TAB */
    UPDATE_ACTIVE_TAB(state, val) {
      state.activeTab = val
    },

    /** UPDATE_TESTRUN_FILTER */
    UPDATE_TESTRUN_FILTER(state, val) {
      state.testRunFilter = val
    },

    /** UPDATE_TEST_RUN_FILTER */
    UPDATE_TEST_RUN_FILTER(state, val) {
      state.test_run_filter = val
    },

    /** UPDATE_TEST_RUN_FILTER */
    UPDATE_TESTPLAN_FILTER(state, val) {
      state.testPlanFilter = val
    },
    /** UPDATE_ORIGINAL_PARENTCHILD_SUITE_LIST */
    UPDATE_ORIGINAL_PARENTCHILD_SUITE_LIST(state, val) {
      state.originalParentChildSuite = val
    },

    /** UPDATE_USER_MANUAL_LIST  */

    UPDATE_USER_MANUAL_LIST(state, val) {
      state.userManualList = val
    },

    /** UPDATE_ORIGINAL_USER_MANUAL_LIST  */
    UPDATE_ORIGINAL_USER_MANUAL_LIST(state, val) {
      state.originaluserManualList = val
    },

    /** UPDATE_BOARD_BACKGROUND_COLOR */
    UPDATE_BOARD_BACKGROUND_COLOR(state, val) {
      state.boardBackGroundColor = val
    },
    UPDATE_LAYOUT_TYPE(state, val) {
      state.layoutType = val
    },
    UPDATE_PRODUCTIVE_PROJECT_LIST(state, val) {
      state.productiveProjectList = val
    },

    UPDATE_DAILYLOG_MODEL_DATA(state, val) {
      state.dailyLogModelData = val
    },

    UPDATE_FEEDBACKCOUNT(state, val) {
      state.feedbackCount = val
    },

    /* UPDATE DEPARTMENT LIST */
    UPDATE_DEPARTMENT_LIST(state, val) {
      state.departmentList = val
    },

    /* UPDATE EMPLOYEE TYPE LIST */
    UPDATE_EMPLOYEE_TYPE_LIST(state, val) {
      state.employeeTypeList = val
    },

    /* UPDATE DIVISION TYPE LIST */
    UPDATE_DIVISION_LIST(state, val) {
      state.divisionList = val
    },
  },
  actions: {
    CLEAR_BOARD_BACKGROUND_COLOR({ commit }) {
      commit('UPDATE_BOARD_BACKGROUND_COLOR', null)
    },
    CLEAR_DEFECT_FILTER({ commit }) {
      commit('UPDATE_DEFECT_FILTER', {
        assignee: null,
        status: [],
        perPage: null,
        search: null,
        suite_id: [],
        severity: [],
      })
    },
    CLEAR_TESTRUN_FILTER({ commit }) {
      commit('UPDATE_TESTRUN_FILTER', {
        search: null,
        perPage: null,
      })
    },
    CLEAR_TEST_RUN_FILTER({ commit }) {
      commit('UPDATE_TEST_RUN_FILTER', {
        search: null,
        status: [],
        perPage: null,
      })
    },
    CLEAR_TESTPLAN_FILTER({ commit }) {
      commit('UPDATE_TESTPLAN_FILTER', {
        search: null,
        perPage: null,
      })
    },
    CLEAR_TESTPLAN_FORM_DATA({ commit }) {
      commit('UPDATE_TEST_PLAN_FORM_DATA', {
        title: null,
        description: null,
        testPlanId: null,
        suiteId: [],
      })
    },
    CLEAR_DAILYLOG_MODEL_DATA({ commit }) {
      commit('UPDATE_DAILYLOG_MODEL_DATA', {
        description: null,
        project_id: null,
        ticket_id: null,
        tags: [],
      })
    },
  },
}

<template>
  <div class="clock-in-out">
    <div class="viewEdit">
      <div class="mb-0 timesheet-header">
        <div class="card-header-edit">

          <div class="card-header-second mt-0">
            <div class="calendar mt-0">
              <div
                id="date-select"
                class="date-select"
              >
                <div
                  class="calender-prev-next"
                  @click="prevWeek(), active = null"
                >
                  <feather-icon
                    icon="ArrowLeftIcon"
                    size="21"
                  />
                </div>
                <div class="date-dropdown">
                  <feather-icon
                    icon="CalendarIcon"
                    class="calendar-icon mr-1"
                    size="15"
                  />
                  <b-dropdown
                    id="dropdown-1"
                    ref="dateRangeFilter"
                    class="date-picker report-date-picker m-0"
                    :text="showDates"
                    @show="closeDropDowns()"
                  >
                    <b-card
                      v-if="dateRangeConfig.mode === 'range'"
                      class="calendar-custom-view"
                    >
                      <b-row class="justify-content-spacebetween">
                        <b-col cols="4">
                          <b-button
                            variant="outline-secondary"
                            :class="active == 'today' ? 'day-btn day-btn-active ' : 'day-btn'"
                            @click="todayDate(), active = 'today'"
                          >Today</b-button>
                          <b-button
                            variant="outline-secondary"
                            :class="active == 'yesterday' ? 'day-btn day-btn-active ' : 'day-btn'"

                            @click="yesterdayDate(), active = 'yesterday'"
                          >Yesterday</b-button>
                          <b-button
                            variant="outline-secondary"
                            :class="active == 'last7days' ? 'day-btn day-btn-active ' : 'day-btn'"

                            @click="last7daysDate(), active = 'last7days'"
                          >Last 7 Days</b-button>
                          <b-button
                            variant="outlines-secondary"
                            :class="active == 'last2week' ? 'day-btn day-btn-active ' : 'day-btn'"

                            @click="last2WeeksDate(), active = 'last2week'"
                          >Last 2 Weeks</b-button>
                          <b-button
                            variant="outline-secondary"
                            :class="active == 'thisMonth' ? 'day-btn day-btn-active ' : 'day-btn'"

                            @click="thisMonth(), active = 'thisMonth'"
                          >This Month</b-button>
                          <b-button
                            variant="outline-secondary"
                            :class="active == 'lastMonth' ? 'day-btn day-btn-active ' : 'day-btn'"

                            @click="lastMonth(), active = 'lastMonth'"
                          >Last Month</b-button>
                          <b-button
                            variant="outline-secondary"
                            :class="active == 'customRange' ? 'day-btn day-btn-active ' : 'day-btn'"

                            @click="customRange(), active = 'customRange'"
                          >Custom Range</b-button>
                          <div
                            class="submit-button d-flex mb-1"
                            :class="from == 'timeLogSummary' ?'pb-1' : null"
                          >
                            <b-button
                              class="side-bar-fillter-calendar"
                              :disabled="!rangeDate"
                              @click="applyDateFilter()"
                            >Apply</b-button>
                            <button
                              class="side-bar-clear-calendar"
                              @click="closeDateFilter()"
                            >
                              Clear
                            </button>
                          </div>
                        </b-col>
                        <b-col cols="8">
                          <flat-pickr
                            v-model="rangeDate"
                            class="form-control"
                            :config="dateRangeConfig"
                          />
                        </b-col>
                      </b-row>
                    </b-card>

                    <b-card
                      v-else
                      class="calendar-custom-view timesheet-calendar-view"
                    >
                      <b-row class="justify-content-spacebetween">
                        <b-col
                          cols="12"
                          lg="12"
                        >
                          <flat-pickr
                            v-model="rangeDate"
                            class="form-control"
                            :config="dateRangeConfig"
                          />
                        </b-col>
                        <b-col
                          cols="12"
                          lg="12"
                          class="submit-button d-flex mt-1 mb-1"
                        >
                          <b-button
                            class="side-bar-fillter-calendar"
                            :disabled="!rangeDate"
                            @click="applyDateFilter()"
                          >
                            Apply
                          </b-button>
                          <button
                            class="side-bar-clear-calendar"
                            @click="closeDateFilter()"
                          >
                            Clear
                          </button>
                        </b-col>
                      </b-row>
                    </b-card>
                  </b-dropdown>
                </div>

                <div
                  class="calender-prev-next calendar-next"
                  @click="nextWeek(), active = null"
                >
                  <feather-icon
                    icon="ArrowRightIcon"
                    size="21"
                  />
                </div>
              </div>
            </div>

          </div>

        </div>
      </div>

    </div>
  </div>
</template>

<script>

import flatPickr from 'vue-flatpickr-component'
import * as moment from 'moment/moment'
import { eventBus } from '@/main'
import FeatherIcon from '../../@core/components/feather-icon/FeatherIcon.vue'

export default {
  name: 'Calender',
  components: {
    FeatherIcon,
    flatPickr,
  },
  props: {
    start: {
      type: String,
      required: false,
      default: null,
    },
    end: {
      type: String,
      required: false,
      default: null,
    },
    dateRangeConfig: {
      type: Object,
      required: false,
      default: () => ({
        maxDate: new Date(),
        mode: 'range',
        inline: true,
      }),
    },
    from: {
      type: String,
      required: false,
      default: null,
    },
    activeBtn: {
      type: String,
      required: false,
      default: null,
    },
  },

  data() {
    return {
      show: false,
      rangeDate: null,
      startDate: null,
      endDate: null,
      active: this.activeBtn,
    }
  },
  computed: {
    showDates() {
      if (this.dateRangeConfig.mode === 'range') {
        return this.startDate ? `${moment(this.startDate).format('ddd, MMM DD, YYYY')} - ${moment(
          this.endDate,
        ).format('ddd, MMM DD, YYYY')}` : 'Select Date'
      }

      return this.startDate ? `${moment(this.startDate).format('ddd, MMM DD, YYYY')}` : 'Select Date'
    },
  },
  watch: {
    start() {
      this.setValueOfDates()
    },
    end() {
      this.setValueOfDates()
    },
    activeBtn(nv) {
      this.active = nv
    },
  },

  mounted() {
    this.setValueOfDates()
  },
  methods: {
    setValueOfDates() {
      this.startDate = this.start ? moment(this.start).format('YYYY-MM-DD') : null
      this.endDate = this.end ? moment(this.end).format('YYYY-MM-DD') : null
      this.rangeDate = `${this.startDate} to ${this.endDate}`
    },
    closeDropDowns() {
      eventBus.$emit('closeCustomDropDown')
    },

    setRangeDate(start, end) {
      this.rangeDate = `${start} to ${end}`
    },

    setCurrentRange(start, end) {
      this.startDate = moment(start).format('YYYY-MM-DD')
      this.endDate = moment(end).format('YYYY-MM-DD')

      this.setRangeDate(this.startDate, this.endDate)
    },

    nextWeek() {
      if (this.startDate && this.endDate) {
        const { startDate, endDate } = this
        const duration = moment(endDate).diff(moment(startDate), 'days')

        const newEndDate = moment(endDate).add(1, 'days')
        const newStartDate = newEndDate.clone().add(duration, 'days')

        if (newStartDate.isAfter(moment(), 'day')) {
          if (!moment(endDate).add(1, 'days').isAfter(moment(), 'day')) {
            this.startDate = this.endDate
          }
          this.endDate = moment().format('YYYY-MM-DD')
          this.rangeDate = `${this.startDate} to ${this.endDate}`
          this.applyDateFilter()
          return
        }

        this.startDate = newEndDate.format('YYYY-MM-DD')
        this.endDate = newStartDate.format('YYYY-MM-DD')
        this.rangeDate = `${this.startDate} to ${this.endDate}`
        this.applyDateFilter()
      }
    },

    prevWeek() {
      if (this.startDate && this.endDate) {
        const start = moment(this.startDate)
        const end = moment(this.endDate)
        const duration = end.diff(start, 'days')
        this.endDate = moment(start).subtract(1, 'days').format('YYYY-MM-DD')
        this.startDate = moment(this.endDate)
          .subtract(duration, 'days')
          .format('YYYY-MM-DD')
        this.rangeDate = `${this.startDate} to ${this.endDate}`
        this.applyDateFilter()
      }
    },

    todayDate() {
      this.setCurrentRange(moment().startOf('day'), moment().endOf('day'))
    },

    yesterdayDate() {
      const yesterday = moment().subtract(1, 'day')
      this.setCurrentRange(yesterday.startOf('day'), yesterday.endOf('day'))
    },

    last7daysDate() {
      this.setCurrentRange(moment().subtract(7, 'days'), moment().subtract(1, 'days'))
    },

    last2WeeksDate() {
      this.setCurrentRange(
        moment().subtract(2, 'weeks').startOf('week'),
        moment().subtract(1, 'weeks').endOf('week'),
      )
    },

    thisMonth() {
      this.setCurrentRange(moment().startOf('month'), moment().endOf('day'))
    },

    lastMonth() {
      const lastMonthStart = moment().subtract(1, 'months').startOf('month')
      const lastMonthEnd = moment().subtract(1, 'months').endOf('month')
      this.setCurrentRange(lastMonthStart, lastMonthEnd)
    },

    customRange() {
      this.rangeDate = null
    },

    applyDateFilter() {
      const [start, end] = this.rangeDate ? this.rangeDate.split(' to ') : []
      this.startDate = start || null
      this.endDate = end || start
      this.$emit('date', {
        startDate: this.startDate, endDate: this.endDate, active: this.active,
      })
      this.$refs.dateRangeFilter.hide()
    },

    closeDateFilter() {
      if (this.from && this.from === 'feedback') {
        this.startDate = null
        this.endDate = null
        this.rangeDate = null
        this.applyDateFilter()
        return
      }

      if (this.from && this.from === 'daily') {
        this.startDate = moment().format('YYYY-MM-DD')
      } else if (this.from && this.from === 'timeLogSummary') {
        this.startDate = moment().startOf('month').format('YYYY-MM-DD')
      } else {
        this.startDate = this.dateRangeConfig.mode === 'range' ? moment().startOf('week').add(1, 'days').format('YYYY-MM-DD') : moment().format('YYYY-MM-DD')
      }
      this.endDate = this.from && this.from === 'weekly' ? moment().endOf('week').add(1, 'days').format('YYYY-MM-DD') : moment().format('YYYY-MM-DD')
      this.rangeDate = `${this.startDate} to ${this.endDate}`
      this.active = null
      this.applyDateFilter()
    },
  },

}
</script>

<style lang="scss">
@import "../../assets/scss/variables/variables";

.viewEdit {
  .line {
    background-color: $secondary-color !important;
  }

  .form-title {
    color: $secondary-color !important;
  }

  .sidebar-form {
    padding: 0 20px;

    .b-sidebar-body {
      padding: 0 20px;

    }

  }

  .set-time-export {
    @media (max-width: map-get($mediaSizes , "md")) {
      order: 1;
      margin-bottom: 5px;
    }

    @media (max-width: map-get($mediaSizes , "sm")) {
      flex-wrap: wrap;

    }

    .side-bar-clear {
      color: $primary-color !important;
      border-color: $primary-color !important;
      font-size: 1.75 * $rem !important;
      font-weight: $fw600 !important;
    }

    .side-bar-fillter {
      background-color: $primary-color !important;
      color: $white;
      margin-left: 20px;
      font-size: 1.75 * $rem !important;
      font-weight: $fw600 !important;
    }
  }

  .calendar-custom-view {
    border-radius: 10px;
    position: absolute;
    top: 10px;
    left: -37px;
    z-index: 11111111;
    width: 700px;
    box-shadow: -4px -4px 12px rgba(0, 0, 0, 0.1),
      4px 4px 12px rgba(0, 0, 0, 0.1) !important;

    @media (max-width: map-get($mediaSizes , "md")) {
      width: calc(100vw - 80%);
      left: 0px;
    }

    @media (max-width: map-get($mediaSizes , "xs")) {
      width: 300px;
    }

    &.timesheet-calendar-view {
      width: 468px;
    }

    .btn {
      &.day-btn {
        width: 193px;
        margin-bottom: 10px;
        text-align: left;
        border-radius: 5px;
        font-size: 1.75 * $rem;
        line-height: 24px;
        font-weight: $fw500;
        color: $black-color;
        border-color: $input-outline !important;

        @media (max-width: map-get($mediaSizes , "lg")) {
          width: 100%;
          margin-right: 10px;
          font-size: 1.5 * $rem;
          padding: 10px;
          height: 40px;
          line-height: 14px;
          margin-bottom: 15px;
        }

        &:focus {
          background-color: $primary-color;
          color: $white !important;
        }
      }
      &.day-btn-active {
        color: $white !important;
        background-color: $primary-color !important;
      }
    }

    .submit-button {
      .btn {
        &.side-bar-fillter-calendar {
          border-color: $secondary-color !important;
          margin-right: 10px;
          padding: 9px 25px;
          color: $white !important;
          font-size: 1.75 * $rem;
          font-weight: $fw600;
          background-color: $secondary-color !important;

          &:focus {
            background-color: $secondary-color !important;
            color: $white !important;
          }

        }
      }

      .side-bar-clear-calendar {
        background-color: transparent !important;
        padding: 9px 25px;
        color: $secondary-color !important;
        border: 1px solid $secondary-color !important;
        border-radius: 5px;
        font-size: 1.75 * $rem;
        font-weight: $fw600;

        @media (max-width: map-get($mediaSizes , "lg")) {
          // margin-bottom: 10px;
        }
      }
    }

    .flatpickr-calendar {
      border-radius: 10px;
      border: 1px solid $input-outline;
      box-shadow: none !important;
      font-size: 1.875 * $rem;
      color: $black-color !important;
      font-weight: $fw500 !important;
      line-height: 25px;
      width: 422px;
      padding: 30px 30px 30px 20px;

      @media (max-width: map-get($mediaSizes , "lg")) {
        width: 100%;
        padding: 12px 12px 12px 10px;
      }

      @media (max-width: map-get($mediaSizes , "xs")) {
        width: 270px;
        padding: 0;
      }

      .flatpickr-innerContainer {
        width: 100%;
      }

      .flatpickr-rContainer {
        width: 100%;
      }

      .dayContainer {
        width: 372px !important;
        max-width: 372px;
        min-width: 372px;

        @media (max-width: map-get($mediaSizes , "xs")) {
          width: 270px;
          max-width: 270px;
          min-width: 270px;
        }

        @media (max-width: map-get($mediaSizes , "lg")) {
          width: 100%;
          max-width: 100%;
          min-width: 100%;
        }
      }

      .flatpickr-days {
        width: 372px;
        font-weight: $fw500 !important;

        @media (max-width: map-get($mediaSizes , "xs")) {
          width: 270px;
        }

        @media (max-width: map-get($mediaSizes , "lg")) {
          width: 100%;
        }
      }

      .flatpickr-day {
        width: 14.28%;
        max-width: unset;
        font-size: 1.875 * $rem;
        font-weight: $fw500;
        height: 46px;
        line-height: 45px;
        flex-basis: 13.285714%;

        &:hover {
          background: $blue;
          border-color: $blue;
        }
      }

      .selected {
        background: $primary-color;
        color: $white !important;
        border-color: $primary-color;
        font-weight: $fw600 !important;

        // box-shadow: -10px 0 0 $primary-color !important;
        &:hover {
          // box-shadow: -5px 0 0 $primary-color, 5px 0 0 $primary-color;
          border-color: $primary-color;
          background-color: $primary-color;
        }
      }

      .today {
        border-color: $dark-gray;
        background-color: $dark-gray;
        color: $white;

        &:hover {
          color: $white;
          background-color: $dark-gray;
        }
      }

      .inRange {
        background-color: $primary-color !important;
        color: $white !important;
        box-shadow: -5px 0 0 $primary-color, 5px 0 0 $primary-color;
        border-color: $primary-color;
        font-weight: $fw600 !important;

        &:hover {
          box-shadow: -5px 0 0 $primary-color, 5px 0 0 $primary-color;
          border-color: $primary-color;
          background-color: $primary-color !important;
        }
      }

      .endRange {
        background: $primary-color;
        color: $white !important;
        border-color: $primary-color !important;
        box-shadow: -10px 0 0 $primary-color !important;

        &:hover {
          // box-shadow: -5px 0 0 $primary-color, 5px 0 0 $primary-color;
          border-color: $primary-color;
          background-color: $primary-color;
        }
      }

      .startRange {
        background: $primary-color;
        color: $white !important;
        border-color: $primary-color;
        box-shadow: none !important;

        &:hover {
          // box-shadow: -5px 0 0 $primary-color, 5px 0 0 $primary-color;
          border-color: $primary-color;
          background-color: $primary-color !important;
        }
      }

      .flatpickr-current-month {

        .flatpickr-monthDropdown-months {
          font-weight: 700;
          padding: 0;
          font-size: 2.25 * $rem;
          line-height: 28px;
          text-align: center;

          &:hover {
            background-color: transparent;
          }
        }

        input {
          &.cur-year {
            font-weight: 700;
            padding: 0;
            font-size: 2.25 * $rem;
            line-height: 28px;

          }
        }

        .numInputWrapper:hover {
          background-color: transparent;
        }
      }

      .flatpickr-prev-month {
        margin-left: 50px;

        @media (max-width: map-get($mediaSizes , "xs")) {
          margin-top: 0 !important;
          margin-left: 20px;
        }
      }

      .flatpickr-next-month {
        margin-right: 50px;

        @media (max-width: map-get($mediaSizes , "xs")) {
          margin-top: 0 !important;
          margin-right: 20px;
        }
      }
    }
  }

  .flatpickr-input {
    display: none !important;
  }

  .dropdown-menu {
    background-color: transparent;
    box-shadow: none;
    border: none;
    top: 4px !important;
    left: -40px !important;
  }

  // new timesheet header
  .timesheet-header {
    border-radius: 0;

    .card-body {
      padding: 10px 15px 10px 20px !important;
    }
  }

  // filter
  .card-filter-section {
    background-color: $white;
    margin: 10px 0px;
    box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1);
    padding: 8px 15px;

    .filter-title {
      color: $logo-color;
      font-size: 2.25 * $rem;
      font-weight: $fw500;
    }

    .selectmember {
      cursor: pointer;
      margin-left: 10px;
      background-color: transparent;
      border-color: transparent;
      color: $logo-color;
      display: flex;
      align-items: center;
      position: relative;

      @media (max-width: map-get($mediaSizes , "2xl")) {
        margin-left: 3px;
      }

      @media (max-width: map-get($mediaSizes , "lg")) {
        margin-left: 0;
        margin: 7px 0;
      }

      span {
        font-size: 1.75* $rem;
        font-weight: $fw500;
        text-transform: capitalize;
        line-height: 23px;
      }

      .feather-edit {
        margin-right: 8px;

        @media (max-width: map-get($mediaSizes , "2xl")) {
          margin-right: 3px;
        }
      }

      .feather-chevron-down {
        margin-left: 8px;
      }

      .badge-pill-filter {
        position: absolute;
        top: -9px;
        right: 13px;
        display: flex;
        height: 20px;
        width: 20px;
        align-items: center;
        justify-content: center;
        padding: 0;
        background-color: rgba(255, 159, 67, 0.7);
      }
    }

    .radio-dropdown {
      position: absolute;
      z-index: 9; //Todo 22-12-22
    }

    .apply-filter {
      background-color: $secondary-color;
      border: 1px solid $secondary-color;
      color: $white;
      padding: 8px 11px;
      border-radius: 5px;
      font-size: 2 * $rem;
      font-weight: $fw600;

      @media (max-width: map-get($mediaSizes , "xxl")) {
        font-size: 2 * $rem;
      }

      @media (max-width: map-get($mediaSizes , "xl")) {
        font-size: 1.75 * $rem;
      }

      @media (max-width: map-get($mediaSizes , "lg")) {
        font-size: 1.5 * $rem;
      }
    }

    .apply-filter-clear {
      border: 1px solid $secondary-color;
      color: $secondary-color;
      padding: 8px 20px;
      border-radius: 5px;
      font-size: 2 * $rem;
      font-weight: $fw600;
      background-color: transparent;

      @media (max-width: map-get($mediaSizes , "xxl")) {
        font-size: 2 * $rem;
      }

      @media (max-width: map-get($mediaSizes , "xl")) {
        font-size: 1.75 * $rem;
      }

      @media (max-width: map-get($mediaSizes , "lg")) {
        font-size: 1.5 * $rem;
      }
    }
  }

  .total-hr-timespent {
    color: $logo-color;
    font-weight: $fw600;
    font-size: 2.25 * $rem;
    text-align: end;
    margin-bottom: 15px;
    margin-right: 15px;
    margin-top: 15px;

    @media (max-width: map-get($mediaSizes , "lg")) {
      font-size: 2 * $rem;
    }

    span {
      color: #FF3700;
    }
  }
}

.flatpickr-innerContainer {
  margin-top: 20px;

  span.flatpickr-weekday {
    font-weight: 400;
    font-size: 12px;
    text-transform: uppercase;
    line-height: 18px;
    color: #2178FB;
  }
}

.flatpickr-calendar .flatpickr-day.flatpickr-disabled,
.flatpickr-calendar .flatpickr-day.prevMonthDay,
.flatpickr-calendar .flatpickr-day.nextMonthDay {
  color: #BDBDBD;
  ;
}

.flatpickr-calendar .flatpickr-day {
  color: #333333;
}

.viewEdit .calendar-custom-view .flatpickr-calendar .flatpickr-day {
  width: 40px;
  height: 40px;
  line-height: 40px;
}

</style>

<template>
  <div>
    <b-col
      v-if="totalCount > 0"
      cols="12"
      class="
          d-flex
          justify-content-between
          flex-wrap
          align-items-center
          table-pagination
          zindex-1
        "
    >
      <p
        v-if="totalCount > 5"
        class="mt-1"
      >
        Showing {{ 1 + (currentPageInternal - 1) * perPage }} to
        <span v-if="currentPageInternal * perPage < totalCount">{{
          currentPageInternal * perPage
        }}</span>
        <span v-else>{{ totalCount }}</span> out of {{ totalCount }} entries
      </p>
      <p
        v-else
        class="mt-1"
      >
        Showing 1 to {{ totalCount }} entries
      </p>
      <b-pagination
        v-if="totalCount > 0"
        v-model="currentPageInternal"
        :total-rows="totalCount"
        :per-page="perPage"
        first-number
        last-number
        align="right"
        prev-class="prev-item"
        next-class="next-item"
        class="mt-1 mb-0"
      >
        <template #prev-text>
          <feather-icon
            icon="ChevronLeftIcon"
            size="18"
          />
        </template>

        <template #next-text>
          <feather-icon
            icon="ChevronRightIcon"
            size="18"
          />
        </template>
      </b-pagination>
    </b-col>
    <!-- pagination section complete here -->
  </div>
</template>

<script>
export default {
  props: {
    currentPage: {
      type: [Number, String],
      required: false,
      default: 1,
    },
    perPage: {
      type: [Number, String],
      required: false,
      default: 10,
    },
    totalCount: {
      type: [Number, String],
      required: false,
      default: 0,
    },
  },
  data() {
    return {
      currentPageInternal: this.currentPage,
    }
  },
  watch: {
    currentPage(newVal) {
      this.currentPageInternal = newVal
    },
    currentPageInternal(newVal) {
      this.$emit('currentPage', newVal)
    },
  },
}
</script>

  <style lang="scss">
  @import "../../assets/scss/variables/_variables.scss";

  .table-pagination {
    padding: 0 39px 20px 39px;
    margin-bottom: 3rem;
    z-index: 1;

    @media (max-width: map-get($mediaSizes , "sm")) {
      flex-direction: column !important;
    }

    .page-item.active {
      .page-link {
        background-color: $black-color !important;
        color: $white !important;
      }
    }

    .page-item {
      .page-link {
        &:hover {
          color: $black-color;
        }
      }
    }

    .page-item.next-item,
    .prev-item {
      .page-link {
        &:hover {
          background-color: $black-color !important;
          color: $white !important;
        }
      }
    }
    .page-item.prev-item {
      .page-link {
        &:hover {
          background-color: #000 !important;
          color: #fff !important;
        }
      }
    }
    p {
      font-size: 2 * $rem;
      font-weight: $fw400;
      line-height: 26px;
      color: #595959;
      font-family: $secondaryFont;

      @media (max-width: map-get($mediaSizes , "sm")) {
        margin-bottom: 15px;
      }
    }
  }
  </style>

<template>
  <a
    class="btnYes d-inline-block help-icon"
    :href="mediaUrl"
    target="_blank"
  >
    <feather-icon
      v-show="mediaUrl"
      v-b-tooltip.hover.bottom="'Need Help?'"
      icon="HelpCircleIcon"
      color="grey"
      class="calendar-icon ml-1"
      size="20"
      role="button"
    />
  </a>
</template>
<script>
export default {
  name: 'Help',
  props: {
    code: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      loader: false,
      mediaUrl: null,
    }
  },
  mounted() {
    this.currentPageDisplay()
  },
  methods: {
    async currentPageDisplay(code) {
      const helpCode = code || this.code
      const data = this.$store.state.app.userManualList.filter(item => item.code === helpCode)
      this.mediaUrl = data && data[0] && data[0].url ? data[0].url : null

      if (!this.mediaUrl) return
      this.loader = true
      const input = {
        url: this.mediaUrl,
      }
      const response = await this.getHTTPPostResponse(
        'admin/master/user-manual/get-temp-url',
        input,
      )

      if (response) {
        this.mediaUrl = response.data && response.data.temp_url ? response.data.temp_url : null
      }
      this.loader = false
    },
  },
}
</script>

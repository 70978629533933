<template>
  <div >
    <div class="card w-100" v-for="(n,nindex) in rowNum" :key="nindex" >
        <!-- <div class="shimmerBG media"></div> -->
        <div class="p-32">
          <div class="shimmerBG title-line"></div>
          <div class="shimmerBG title-line"></div>
          <!-- <div class="shimmerBG title-line end"></div> -->

          <div class="shimmerBG content-line m-t-24"></div>
          <div class="shimmerBG content-line"></div>
          <div class="shimmerBG content-line"></div>
          <div class="shimmerBG content-line"></div>
          <div class="shimmerBG content-line end"></div>
        </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "timesheet-loader",
  props: {
    rowNum: {
      // type: String,
      required: true,
      default: 1
    },
  },
};
</script>
<style lang="scss" scoped>

.card {
    position: relative;
    -webkit-box-shadow: 0 6px 8px rgba(0, 0, 0, 0.1);
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.1);
    background-color: #FFF;
    border-radius: 6px;
    height: auto;
    overflow: hidden;
    width: 100%;
    margin: 40px auto;
  
  .shimmerBG {
    animation-duration: 2.2s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: shimmer;
    animation-timing-function: linear;
    background: #ddd;
    background: linear-gradient(to right, #F6F6F6 8%, #F0F0F0 18%, #F6F6F6 33%);
    background-size: 1200px 100%;
}


@-webkit-keyframes shimmer {
    0% {
        background-position: -100% 0;
    }
    100% {
        background-position: 100% 0;
    }
}

@keyframes shimmer {
    0% {
        background-position: -1200px 0;
    }
    100% {
        background-position: 1200px 0;
    }
}
  
    .media {
    height: 200px;
}

.p-32 {
    padding: 32px;
}

.title-line {
    height: 24px;
    width: 100%;
    margin-bottom: 12px;
    border-radius: 20px;
}

.content-line {
    height: 8px;
    width: 100%;
    margin-bottom: 16px;
    border-radius: 8px;
}
  
  .end {
      width: 40%;
    }

}


.m-t-24 {
    margin-top: 24px;
}
</style>
 
<template>
  <div>
    <b-modal
      v-model="$store.state.app.isUserProfileOpen"
      centered
      content-class="task-deatil-popup"
      hide-footer
    >
      <template #modal-title>
        <h3 class="table-title-text">
          User Detail

        </h3>
      </template>
      <div class="container mt-1 mb-50">

        <!-- User Profile Info -->
        <div
          v-if="userDetail"
          class="row"
        >
          <div class="col-lg-6 mb-50 mb-lg-0">
            <div class="profile-image-wrapper d-flex flex-column align-items-center">
              <b-avatar
                size="114"
                variant="light-danger"
                :src="userDetail.picture"
                :text="userDetail && userDetail.full_name.charAt(0) || null"
              />
              <div class="mt-1 text-center">
                <h3>{{ (userDetail && userDetail.full_name) || null }}</h3>
                <p>{{ userDetail && userDetail.email }}</p>
                <p>{{ userDetail && userDetail.phone }}</p>
              </div>

            </div>
          </div>
          <div class="col-lg-6 d-flex flex-column align-items-center">
            <div class="  ">
              <div class="mb-50">
                <h6 class="text-muted">
                  Managed By </h6>
                <p>{{ userDetail && userDetail.manage_by_user && userDetail.manage_by_user.full_name }}</p>
              </div>
              <div class="mb-50">
                <h6 class="text-muted">
                  Reporting To </h6>
                <p>{{ userDetail && userDetail.users && userDetail.users.full_name }}</p>
              </div>
              <div

                class="mb-50"
              >
                <h6 class="text-muted">
                  Primary Skill </h6>

                <div
                  v-if="userDetail && userDetail.primary && userDetail.primary.length"
                  class="mt-0"
                >
                  <b-badge
                    v-for="(skill, index) in userDetail.primary"
                    variant="secondary"
                    class="mr-1 mt-25"
                    :variant="skillBackground()"
                  >
                    {{ skill.name }}
                  </b-badge>
                </div>
                <div v-else>
                  N/A
                </div>
              </div>
              <div

                class="mb-50"
              >
                <h6 class="text-muted">
                  Secondary Skills </h6>
                <div
                  v-if="userDetail && userDetail.secondary && userDetail.secondary.length"
                  class="mt-0"
                >
                  <b-badge
                    v-for="(skill, index) in userDetail.secondary"
                    :key="index"
                    variant="secondary"
                    class="mr-1 mt-25"
                    :variant="skillBackground()"
                  >
                    {{ skill && skill.name }}
                  </b-badge>
                </div>
                <div v-else>
                  N/A
                </div>
              </div>

              <div class="mb-50">
                <h6 class="text-muted">
                  Department </h6>
                <div
                  v-if="userDetail && userDetail.department && userDetail.department.name"
                  class="mt-0"
                >
                  <b-badge
                    variant="secondary"
                    class="mr-1 mt-25"
                    :variant="skillBackground()"
                  >
                    {{ userDetail.department.name }}
                  </b-badge>
                </div>
                <div v-else>
                  N/A
                </div>
              </div>
              <div class="mb-50">
                <h6 class="text-muted">
                  Division </h6>
                <div
                  v-if="userDetail && userDetail.division && userDetail.division.name"
                  class="mt-0"
                >
                  <b-badge
                    variant="secondary"
                    class="mr-1 mt-25"
                    :variant="skillBackground()"
                  >
                    {{ userDetail.division.name }}
                  </b-badge>
                </div>
                <div v-else>
                  N/A
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
export default {
  props: {
    userId: {
      required: true,
      default: null,
    },
    modalShow: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      userDetail: null,
    }
  },

  watch: {

    modalShow(nv) {
      if (nv === true) {
        this.show = nv
      }
    },
    userId() {
      if (this.userId && this.modalShow) this.getUserInfo()
    },
  },
  mounted() {
    if (this.userId) this.getUserInfo()
  },

  methods: {

    async getUserInfo() {
      this.loader = true
      if (!this.$store.state.app.viewUserProfileId) return
      const response = await this.getHTTPGetResponse(
        `admin/master/user/view/${this.$store.state.app.viewUserProfileId}`,
        {},
        false,
      )
      if (response && response.data) {
        const data = response.data.user
        this.userDetail = data
        this.loader = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import "../../assets/scss/variables/_variables.scss";

.profile-image-wrapper {
  border-radius: 8px;
  overflow: hidden;
  padding: 10px;
}

h2 {
  font-size: 2 * $rem;
  font-weight: $fw600;
  color: $primary-color;

  @media (max-width: map-get($mediaSizes , "xl")) {
    font-size: 14px;
    margin: 0 0 8px 0;
  }
}

.container {
  max-width: 1500px;
}

.table-title-text {
  font-size: 20px;
  margin: 0;

  @media (max-width: map-get($mediaSizes , "xl")) {
    font-size: 18px;
  }
}

h6 {
  color: #555555 !important;
  font-weight: 600;
}

p {
  color: #888888;
  font-weight: 500;
}

</style>
